<script setup lang="ts">
import { theme } from "@/utils/Colors";
import { useRouter } from "vue-router";
const router = useRouter();

const onTitleClick = () => {
  router.push({
    name: "home",
  });
};

const onContactClick = () => {
  router.push({
    name: "contact",
  });
};

const onPrivacyPolicyClick = () => {
  router.push({
    name: "privacy_policy",
  });
};

const onTermsOfServicesClick = () => {
  router.push({
    name: "terms_of_services",
  });
};
</script>
<template>
  <div class="application">
    <div class="top-app-bar">
      <figure class="top-app-bar-figure">
        <img
          class="top-app-bar-logo"
          :src="require(`@/assets/logo_graphity.png`)"
          :alt="'Graphity'"
          v-on:click="onTitleClick"
        />
      </figure>

      <p class="top-app-bar-title" v-on:click="onTitleClick">Graphity</p>
    </div>
    <router-view />
    <div class="bottom-app-bar">
      <p class="bottom-app-bar-link" v-on:click="onContactClick">Kontakt</p>
      <p class="bottom-app-bar-link" v-on:click="onPrivacyPolicyClick">
        Ochrana osobních údajů
      </p>
      <p class="bottom-app-bar-link" v-on:click="onTermsOfServicesClick">
        Podmínky použití
      </p>
    </div>
  </div>
</template>

<style>
.title-medium {
  cursor: pointer;
}

.top-app-bar {
  background-color: v-bind("theme.surfaceContainer");
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  flex-direction: row;
  gap: 8px;
}

.top-app-bar-title {
  font-family: "Lexend", sans-serif;
  font-size: 22px;
  line-height: 28px;
  text-align: start;
  margin: 0;
  cursor: pointer;
}

.top-app-bar-logo {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  align-self: center;
  cursor: pointer;
  display: block;
}
.top-app-bar-figure {
  margin: 0;
}

.bottom-app-bar-link {
  font-family: "Lexend", sans-serif;
  cursor: pointer;
  margin: 0;
}

.bottom-app-bar {
  background-color: v-bind("theme.surfaceContainer");
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 56px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

#app {
  font-family: "Lexend", sans-serif;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
