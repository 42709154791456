import "mdb-vue-ui-kit/css/mdb.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getFirebase } from "./firebase";
import { VueFire, VueFireAuth } from "vuefire";
import "vue3-toastify/dist/index.css";
import "bootstrap/dist/css/bootstrap.css";
import "./main.css";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  faGooglePlay,
  faApple,
  faWindows,
  faAppStoreIos,
} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(faCloudArrowDown, faGooglePlay, faApple, faWindows, faAppStoreIos);

//https://www.youtube.com/watch?v=N-CS73eHld0
const { firebaseApp } = getFirebase();

createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
