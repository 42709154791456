import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getCurrentUser } from "vuefire";

const routes: Array<RouteRecordRaw> = [
  /*
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import( "../views/Profile.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () =>
      import( "../views/Auth.vue"),
  },
  {
    path: "/login",
    name: "logn",
    component: () =>
      import( "../views/Login.vue"),
  },
  {
    path: "/tasks/:authorId/created",
    name: "created_tasks",
    component: () =>
      import( "../views/TaskList.vue"),
    props: true,
  },
  {
    path: "/tasks/:authorId/:setId?",
    name: "set",
    component: () =>
      import( "../views/TaskList.vue"),
    props: true,
  },
  {
    path: "/sets/:authorId",
    name: "sets",
    component: () =>
      import( "../views/SetList.vue"),
    props: true,
  },
  {
    path: "/tasks/:authorId/favourite",
    name: "favourite_tasks",
    component: () =>
      import( "../views/TaskList.vue"),
    props: true,
  },
  {
    path: "/task/:authorId?/:taskId?/edit",
    name: "task_edit",
    component: () =>
      import( "../views/TaskEdit.vue"),
    props: true,
  },
  {
    path: "/task/:authorId?/:taskId?",
    name: "task",
    component: () =>
      import( "../views/Task.vue"),
    props: true,
  },
  */
  {
    path: "/",
    name: "home",
    component: () => import("../views/v2/Home.vue"),
    props: true,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
    props: true,
  },
  {
    path: "/terms_of_services",
    name: "terms_of_services",
    component: () => import("../views/TermsOfServices.vue"),
    props: true,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicy.vue"),
    props: true,
  },
  {
    path: "/mac",
    name: "mac",
    component: () => import("../views/v2/Mac.vue"),
    props: true,
  },
  {
    path: "/windows",
    name: "windows",
    component: () => import("../views/v2/Windows.vue"),
    props: true,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {});

export default router;
