export interface ColorPallete {
  primary: string;
  surfaceTint: string;
  onPrimary: string;
  primaryContainer: string;
  onPrimaryContainer: string;
  secondary: string;
  onSecondary: string;
  secondaryContainer: string;
  onSecondaryContainer: string;
  tertiary: string;
  onTertiary: string;
  tertiaryContainer: string;
  onTertiaryContainer: string;
  error: string;
  onError: string;
  errorContainer: string;
  onErrorContainer: string;
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  surfaceVariant: string;
  onSurfaceVariant: string;
  outline: string;
  outlineVariant: string;
  shadow: string;
  scrim: string;
  inverseSurface: string;
  inverseOnSurface: string;
  inversePrimary: string;
  primaryFixed: string;
  onPrimaryFixed: string;
  primaryFixedDim: string;
  onPrimaryFixedVariant: string;
  secondaryFixed: string;
  onSecondaryFixed: string;
  secondaryFixedDim: string;
  onSecondaryFixedVariant: string;
  tertiaryFixed: string;
  onTertiaryFixed: string;
  tertiaryFixedDim: string;
  onTertiaryFixedVariant: string;
  surfaceDim: string;
  surfaceBright: string;
  surfaceContainerLowest: string;
  surfaceContainerLow: string;
  surfaceContainer: string;
  surfaceContainerHigh: string;
  surfaceContainerHighest: string;
  onSurfaceOpacityLow: string;
  onSurfaceOpacityMedium: string;
  onPrimaryOpacityLow: string;
  onPrimaryOpacityMedium: string;
  primaryOpacityLow: string;
  primaryOpacityMedium: string;
  scrimWithHighOpacity: string;
}

const theme: ColorPallete = {
  primary: "#48672E",
  surfaceTint: "#48672E",
  onPrimary: "#FFFFFF",
  primaryContainer: "#C9EEA7",
  onPrimaryContainer: "#0C2000",
  secondary: "#56624A",
  onSecondary: "#FFFFFF",
  secondaryContainer: "#DAE7C9",
  onSecondaryContainer: "#141E0C",
  tertiary: "#386665",
  onTertiary: "#FFFFFF",
  tertiaryContainer: "#BBECE9",
  onTertiaryContainer: "#00201F",
  error: "#BA1A1A",
  onError: "#FFFFFF",
  errorContainer: "#FFDAD6",
  onErrorContainer: "#410002",
  background: "#F9FAEF",
  onBackground: "#1A1D16",
  surface: "#F9FAEF",
  onSurface: "#1A1D16",
  surfaceVariant: "#E0E4D6",
  onSurfaceVariant: "#44483E",
  outline: "#74796D",
  outlineVariant: "#C4C8BA",
  shadow: "#000000",
  scrim: "#000000",
  inverseSurface: "#2E312A",
  inverseOnSurface: "#F0F2E7",
  inversePrimary: "#AED18D",
  primaryFixed: "#C9EEA7",
  onPrimaryFixed: "#0C2000",
  primaryFixedDim: "#AED18D",
  onPrimaryFixedVariant: "#314E19",
  secondaryFixed: "#DAE7C9",
  onSecondaryFixed: "#141E0C",
  secondaryFixedDim: "#BECBAE",
  onSecondaryFixedVariant: "#3F4A34",
  tertiaryFixed: "#BBECE9",
  onTertiaryFixed: "#00201F",
  tertiaryFixedDim: "#A0CFCD",
  onTertiaryFixedVariant: "#1E4E4D",
  surfaceDim: "#D9DBD0",
  surfaceBright: "#F9FAEF",
  surfaceContainerLowest: "#FFFFFF",
  surfaceContainerLow: "#F3F5EA",
  surfaceContainer: "#EDEFE4",
  surfaceContainerHigh: "#E7E9DE",
  surfaceContainerHighest: "#E2E3D9",
  onSurfaceOpacityLow: "rgba(29, 27, 32, 0.08)",
  onSurfaceOpacityMedium: "rgba(29, 27, 32, 0.12)",
  onPrimaryOpacityLow: "rgba(255, 255, 255, 0.08)",
  onPrimaryOpacityMedium: "rgba(255, 255, 255, 0.12)",
  primaryOpacityLow: "rgba(72, 103, 46, 0.08)",
  primaryOpacityMedium: "rgba(72, 103, 46, 0.12)",
  scrimWithHighOpacity: "rgba(0, 0, 0, 0.8)",
};
export { theme };
