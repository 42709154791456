// Import the functions you need from the SDKs you need
import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCqbwpfP262LfhECdZfLdTxZnrHerukEu4",
  authDomain: "jvo-graphity.firebaseapp.com",
  projectId: "jvo-graphity",
  storageBucket: "jvo-graphity.appspot.com",
  messagingSenderId: "337487185425",
  appId: "1:337487185425:web:bc80998f7e063bda2853b0",
  measurementId: "G-F585JZZPP9",
};

function initialize(existingApp: FirebaseApp | undefined) {
  const firebaseApp = existingApp || initializeApp(firebaseConfig);
  const auth = getAuth(firebaseApp);
  const firestore = getFirestore(firebaseApp);
  return { firebaseApp, auth, firestore };
}

export function getFirebase() {
  const existingApp = getApps()[0];
  return initialize(existingApp);
}
